import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom';
import totalleads from "../../assets/images/dashboardimg/totalleads.png";
import totalindivisuallead from "../../assets/images/dashboardimg/totalindivisuallead.png";
import totalfirm from "../../assets/images/dashboardimg/totalfirm.png";
import totalclient from "../../assets/images/dashboardimg/totalclient.png";
import leadmain from "../../assets/images/dashboardimg/leadmain.png";
import convertedlead from "../../assets/images/dashboardimg/convertedlead.png";
import registeredlead from "../../assets/images/dashboardimg/registeredlead.png";
import closedlead from "../../assets/images/dashboardimg/closedlead.png";
import clientlead from "../../assets/images/dashboardimg/clientlead.png";
//import maincontactimage from "../../assets/images/c2.png";

import maincontactimage from "../../assets/images/users/avatar-1.jpg";


import axios from 'axios'
//import Apaexlinecolumn from "pages/AllCharts/apex/apaexlinecolumn";
import ReactApexChart from "react-apexcharts"
import e from 'cors';
const Dashboard = () => {

  const [TotalLead, setTotalLead] = useState("0");
  const [TotalIndividualLead, setTotalIndividualLead] = useState("0");
  const [TotalFirmCompanyLead, setTotalFirmCompanyLead] = useState("0");
  const [TotalEnrollLead, setTotalEnrollLead] = useState("0");
  const [TotalIndividualLeadEnroll, setTotalIndividualLeadEnroll] = useState("0");
  const [TotalFirmCompanyLeadEnroll, setTotalFirmCompanyLeadEnroll] = useState("0");
  const [TotalConvertLead, setTotalConvertLead] = useState("0");
  const [TotalConvertIndividualLead, setTotalConvertIndividualLead] = useState("0");
  const [TotalConvertFirmCompanyLead, setTotalConvertFirmCompanyLead] = useState("0");
  const [TotalRegisterLead, setTotalRegisterLead] = useState("0");
  const [TotalRegisterIndividualLead, setTotalRegisterIndividualLead] = useState("0");
  const [TotalRegisterFirmCompanyLead, setTotalRegisterFirmCompanyLead] = useState("0");
  const [TotalCloseLead, setTotalCloseLead] = useState("0");
  const [TotalCloseIndividualLead, setTotalCloseIndividualLead] = useState("0");
  const [TotalCloseFirmCompanyLead, setTotalCloseFirmCompanyLead] = useState("0");
  const [TotalClient, setTotalClient] = useState("0");
  const [TotalIndividualClient, setTotalIndividualClient] = useState("0");
  const [TotalFirmCompanyClient, setTotalFirmCompanyClient] = useState("0");

  const [LeadList, setLeadList] = useState([]);
  const [EnrolledList, setEnrolledList] = useState([]);
  const [ConvertedList, setConvertedList] = useState([]);
  const [RegisteredList, setRegisteredList] = useState([]);
  const [ClosedList, setClosedList] = useState([]);
  const [ClientList, setClientList] = useState([]);
  const Total = TotalLead + TotalEnrollLead + TotalConvertLead + TotalRegisterLead + TotalCloseLead + TotalClient;

  const [DisplayLayoutTypeCount, setDisplayLayoutTypeCount] = useState('col-md-3');


  const [CorporateDetails, setCorporateDetails] = useState([]);
  const [ConvertedContact, setConvertedContact] = useState([]);
  const [MeetingDetails, setMeetingDetails] = useState([]);

  const [CorporateMeetingList, setCorporateMeetingList] = useState([])
  const [CorporateMeetingFilterList, setCorporateMeetingFilterList] = useState([])

  const [LoginType, setLoginType] = useState('');

  const [CorporateServicesPackageList, setCorporateServicesPackageList] = useState([])
  const [CorporateDetail, setCorporateDetail] = useState([])

  const [CorporateServicesPackagePaymentList, setCorporateServicesPackagePaymentList] = useState([])

  useEffect(() => {
    let VLoginType = localStorage.getItem("LoginType");
    setLoginType(VLoginType);
    getCorporateContactInfo();
    getClientConvertedList();
    getCorLeadMomList();
    getCorporateServicesPackageList();
    getCorporateDetail();
    getCorporateServicesPackagepaymentList();
  }, []);

  const getCorporateContactInfo = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");
    let VCorporateleadContactId = localStorage.getItem("CorporateleadContactId");
    let VLoginType = localStorage.getItem("LoginType");
    if (VLoginType == "MainLogin") {
      const CorporateData = {
        CorLeadId: VCorporateleadId,
        CorInformationLeadId: ""
      }
      axios.post(global.APIURL + '/CorporateContactInfo', CorporateData)
        .then(res => {
          setCorporateDetails(res.data);
        });
    }
    else if (VLoginType == "SubLogin") {
      const CorporateData = {
        CorLeadId: "",
        CorInformationLeadId: VCorporateleadContactId
      }
      axios.post(global.APIURL + '/CorporateContactInfo', CorporateData)
        .then(res => {
          setCorporateDetails(res.data);
        });
    }
    else {
      setCorporateDetails([]);
    }
  }

  const getCorporateServicesPackageList = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const CorporateData = {
      Id: VCorporateleadId,
    }
    axios.post(global.APIURL + '/CorporateServicesPackage', CorporateData)
      .then(res => {
        console.log("res",res);
        setCorporateServicesPackageList(res.data);
      });
  }


  const getCorporateServicesPackagepaymentList = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const CorporatepaymentData = {
      Id: VCorporateleadId,
    }
    axios.post(global.APIURL + '/CorporateLeadServiceppayment', CorporatepaymentData)
      .then(res => {
        setCorporateServicesPackagePaymentList(res.data);
      });
  }

  const getCorporateDetail = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const CorporateData = {
      Id: VCorporateleadId,
    }
    axios.post(global.APIURL + '/CorporateDetail', CorporateData)
      .then(res => {
        setCorporateDetail(res.data);
      });
  }


  const getClientConvertedList = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");
    let VCorporateleadContactId = localStorage.getItem("CorporateleadContactId");
    let VCorporateLead = localStorage.getItem("CorporateLead");

    if (VCorporateLead == "YES") {
      const CorporateData = {
        CorLeadId: "",
        CorInformationLeadId: VCorporateleadContactId
      }

      axios.post(global.APIURL + '/CClientDashboardconvetedList', CorporateData)
        .then(res => {
          setConvertedContact(res.data);
        });
    }
    else if (VCorporateLead == "") {

      const CorporateData = {
        CorLeadId: VCorporateleadId,
        CorInformationLeadId: ""
      }

      axios.post(global.APIURL + '/CClientDashboardconvetedList', CorporateData)
        .then(res => {
          setConvertedContact(res.data);
        });
    }
  }


  const getCorLeadMomList = () => {
    let VCorporateId = localStorage.getItem("CorporateleadId");
    let VCorporateContactId = localStorage.getItem("CorporateleadContactId");
    let VLoginType = localStorage.getItem("LoginType");
    const CorporateData = {
      CorporateId: VCorporateId == null ? '0' : VCorporateId == '' ? '0' : VCorporateId,
      CorporateContactId: VCorporateContactId == null ? '0' : VCorporateContactId == '' ? '0' : VCorporateContactId,
      CorporateLoginType: VLoginType == null ? '' : VLoginType,
      SpMode: 'DashboardSeleteAll'
    }

    axios.post(global.APIURL + '/WebCorporateLeadMomList', CorporateData)
      .then(res => {
        var VCorporateMeetingList = [];
        for (let cm = 0; cm < res.data.length; cm++) {
          let VMeetingheadnote = res.data[cm].Meetingheadnote;
          let Meetingsummary = res.data[cm].Meetingsummary;
          let MeetingDay = res.data[cm].MDDay;
          let MeetingMonth = res.data[cm].MDMonth;
          let MeetingYear = res.data[cm].MDYear;
          let Meetingtime = res.data[cm].Meetingtime;
          let Nextmeetingdate = res.data[cm].Nextmeetingdate;
          var Agenda = res.data[cm].Agenda;
          let Attachment = res.data[cm].Attachment == null ? '' : res.data[cm].Attachment;
          let Point = res.data[cm].Point;
          let Name = res.data[cm].Name;
          let Email = res.data[cm].Email;
          let Mobilenumber = res.data[cm].Mobilenumber;
          let Address = res.data[cm].Address;
          let MeetingWith = res.data[cm].MeetingWith;


          let AgendaArr = Agenda == null ? [] : Agenda == null ? [] : Agenda.split('+');
          var VAgendaList = [];
          for (let ag = 0; ag < AgendaArr.length; ag++) {
            const AgendaTitle = AgendaArr[ag];
            const ATItem = {
              Agenda: AgendaTitle,
            };
            VAgendaList.push(ATItem);
          }


          let PointArr = Point == null ? [] : Point == null ? [] : Point.split('+');
          var VPointList = [];
          for (let ag = 0; ag < PointArr.length; ag++) {
            const PointTitle = PointArr[ag];
            const PTItem = {
              Point: PointTitle,
            };
            VPointList.push(PTItem);
          }


          const CorporateMeetingItem = {
            Meetingheadnote: VMeetingheadnote,
            Meetingsummary: Meetingsummary,
            MeetingDay: MeetingDay,
            MeetingMonth: MeetingMonth,
            MeetingYear: MeetingYear,
            Meetingtime: Meetingtime,
            Nextmeetingdate: Nextmeetingdate,
            Address: Address,
            AgendaList: VAgendaList,
            PointList: VPointList,
            MeetingWith: MeetingWith,
            Name: Name
          };
          VCorporateMeetingList.push(CorporateMeetingItem);
        }
        setCorporateMeetingList(VCorporateMeetingList);
      });
  }

  const ViewMeetingDetails = async (e, MeetingId) => {
    var CorporateMeetingListFilter = CorporateMeetingList.filter(x => x.MId == MeetingId);
    setCorporateMeetingFilterList(CorporateMeetingListFilter);
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | KC Admin Group</title>
        </MetaTags>
        <div className="container-fluid">

          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Meeting Details</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body mainmodalmeetingdestails">

                  {CorporateMeetingList.map((item, idx) => (
                    <div key={idx}>
                      <div className="d-flex mb-2">
                        <div className="">
                          <div className="maindatemeetingbox">
                            <p>{item.MeetingDay}</p>
                            <p>{item.MeetingMonth}</p>
                            <p>{item.MeetingYear}</p>
                          </div>




                        </div>
                        <div >
                          <p className="meetingheadingnotsmain"> {item.Meetingheadnote} </p>
                          <div >

                            <div className="d-flex">
                              <div>
                                <i className="las la-clock clockiconedit"></i>
                              </div>
                              <div>
                                <p className="meetingtimebox">{item.Meetingtime}</p>
                              </div>
                            </div>
                          </div>

                          <div >

                            <div className="d-flex">
                              <div>
                                <i className="las la-map-marker locationiconnew"></i>
                              </div>
                              <div>
                                <p className="locationmaindatatext">{item.Address}</p>
                              </div>
                            </div>
                          </div>



                        </div>

                      </div>
                      {LoginType == 'MainLogin' ? <div>
                        <div className="d-flex align-items-center">
                          <p className="boxmeetingtitle me-2">Attendee :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.Name}</p>
                        </div>
                        <hr className="mt-2 mb-2"></hr>
                      </div>
                        : ''}
                      <div>
                        <div className="d-flex align-items-center">
                          <p className="boxmeetingtitle me-2">Meeting With :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.MeetingWith}</p>
                        </div>
                        <hr className="mt-2 mb-2"></hr>
                      </div>

                      <div>
                        <p className="boxmeetingtitle">Agenda</p>
                        <hr className="mt-2 mb-2"></hr>
                        <div>
                          <div>
                            {item.AgendaList.map((Aitem, idx1) => (
                              <div className="d-flex" key={idx1}>
                                <div>
                                  <i className="fa-solid fa-circle pointsimageedit"></i>
                                </div>
                                <div>
                                  <p className="pointstextedit">{Aitem.Agenda}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="boxmeetingtitle">Point</p>
                        <hr className="mt-2 mb-2"></hr>
                        <div>
                          {item.PointList.map((Pitem, idx2) => (
                            <div className="d-flex" key={idx2}>
                              <div>
                                <i className="fa-solid fa-circle pointsimageedit"></i>
                              </div>
                              <div>
                                <p className="pointstextedit">{Pitem.Point}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div>
                        <p className="boxmeetingtitle">Meeting summary</p>
                        <hr className="mt-2 mb-2"></hr>
                        <div>
                          <div className="d-flex">
                            <p className="pointstextedit">{item.Meetingsummary}</p>
                          </div>
                        </div>
                      </div>


                    </div>
                  ))}


                </div>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Corporate Information</p>
                  {CorporateDetail?.map((item, idx) => (
                    <div className="ciboxmain" key={idx}>
                      <div className="mainciboxgride">
                        <div >
                          <p className="ciboxtitlegrid">Network Type</p>
                          <p className="mainciboxgriddescription">{item.ChannelType}</p>

                        </div>

                        <div >
                          <p className="ciboxtitlegrid">Corporate</p>
                          <p className="mainciboxgriddescription">{item.ChannelMaster}</p>

                        </div>



                        <div >
                          <p className="ciboxtitlegrid">Email Address</p>
                          <p className="mainciboxgriddescription">{item.EmailAddress}</p>

                        </div>
                        <div >
                          <p className="ciboxtitlegrid">Branch Code</p>
                          <p className="mainciboxgriddescription">{item.Branchcode}</p>

                        </div>

                        <div >
                          <p className="ciboxtitlegrid">Corporate Status</p>
                          <p className="mainciboxgriddescription">{item.CorporateStatus}</p>

                        </div>

                        <div >
                          <p className="ciboxtitlegrid">Registered Date</p>
                          <p className="mainciboxgriddescription">{item.RegisterDate}</p>

                        </div>

                        <div >
                          <p className="ciboxtitlegrid">Converted Date</p>
                          <p className="mainciboxgriddescription">{item.ConvertDate}</p>

                        </div>


                      </div>
                      <div className="mainaddressboxci">
                        <div className="w-100">
                          <p className="ciboxtitlegrid">Address</p>
                          <p className="mainciboxgriddescription">{item.Address}</p>

                        </div>
                        <div className="closedatenamebox">
                          <p className="ciboxtitlegrid">Closed Date</p>
                          <p className="mainciboxgriddescription">{item.CloseDate}</p>

                        </div>
                        <div>

                        </div>

                      </div>

                    </div>
                  ))}
                </div>

              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Corporate Contact</p>
                  {LoginType == 'MainLogin' ?
                  <div className="row">
                     {CorporateDetails.map((item, idx) => (
                    <div className="col-md-3" key={idx}>
                      <div className="ccboxmaindashb">
                        <div className="d-flex">
                          <div className="ccboxmaindashbimgdiv">
                            <img src={maincontactimage}></img>

                          </div>
                          <div>

                            <p className="ciboxtitlegrid mb-1">{item.Name}</p>
                            <p className="mainciboxgriddescription">{item.Designation}</p>

                          </div>

                        </div>
                        <div className="ccboxlinetext"></div>
                        <div className="d-flex ccdetailscall mb-1">
                          <div>
                            <i className="las la-phone-volume "></i>
                          </div>
                          <div>
                            <p className="mb-0">{item.Mobilenumber}</p>
                          </div>
                        </div>
                        <div className="d-flex ccmaildetails">
                          <div>
                            <i className="las la-envelope  "></i>
                          </div>
                          <div>
                            <p className="mb-0">{item.Email}</p>
                          </div>
                        </div>

                      </div>

                    </div>
                     ))}

                  </div>: ''}


                </div>

              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Registration Services Package</p>
                
                  <div className='maindasboardtableeditnew'>
                          <table className="table mt-0 mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col">Services Package</th>
                                <th scope="col" className='text-center'>Type</th>
                                <th scope="col" className='text-center'>Price</th>
                                <th scope="col" className='text-center'>Total Amount</th>
                                <th scope="col" className='text-center'>Recived Amount</th>
                                <th scope="col" className='text-center'>Due Amount</th>
                               
                                
                              </tr>
                            </thead>
                            <tbody>
                              {CorporateServicesPackageList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td><a className='shyperlink' href="/ManageInformation" >{item.ServiceTitle}</a></td>
                                  <td className='text-center'>{item.ServicesPackageType}</td>
                                  <td className='text-center'>{item.PlanAmount}</td>
                                  <td className='text-center'>{item.TotalAmount}</td>
                                  <td className='text-center'>{item.TotalRecivedAmount}</td>
                                  <td className='text-center'>{item.TotalDueAmount}</td>
                                  <td className='text-center'></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                </div>

              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Registration Services Package Due payment</p>
                
                  <div className='maindasboardtableeditnew'>
                          <table className="table mt-0 mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col">Services Package</th>
                                <th scope="col" className='text-center'>Type</th>
                                <th scope="col" className='text-center'>Due Date</th>
                                <th scope="col" className='text-center'>Amount</th>
                                <th scope="col" className='text-center'>Gst Amount</th>
                                <th scope="col" className='text-center'>Total Amount</th>
                                <th scope="col" className='text-center'>Payment Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {CorporateServicesPackagePaymentList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td>{item.ServiceTitle}</td>
                                  <td className='text-center'>{item.ServicesPackageType}</td>
                                  <td className='text-center'>{item.DueDate}</td>
                                  <td className='text-center'>{item.Amount}</td>
                                  <td className='text-center'>{item.GstAmount}</td>
                                  <td className='text-center'>{item.TotalAmount}</td>
                                  <td className='text-center'>{item.PaymentStatus}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                </div>

              </div>
            </div>

          </div>
          <Row>
            <Col xl='12'>
       
              {/* <Row>
                <Col md="12" className="mb-2">
                  <Card className="mini-stats-wid ">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2">Registration Services Package</CardTitle>
                        </Col>
                        <hr />
                      </Row>
                      <Col className="col-lg-12 mb-2 mt-2">
                        <div>
                          <table className="table">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col">Services Package</th>
                                <th scope="col" className='text-center'>Type</th>
                                <th scope="col" className='text-center'>Price</th>
                                <th scope="col" className='text-center'>Total Amount</th>
                                <th scope="col" className='text-center'>Recived Amount</th>
                                <th scope="col" className='text-center'>Due Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {CorporateServicesPackageList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td>{item.ServiceTitle}</td>
                                  <td className='text-center'>{item.ServicesPackageType}</td>
                                  <td className='text-center'>{item.PlanAmount}</td>
                                  <td className='text-center'>{item.TotalAmount}</td>
                                  <td className='text-center'>{item.TotalRecivedAmount}</td>
                                  <td className='text-center'>{item.TotalDueAmount}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
              {/* {LoginType == 'MainLogin' ?
                <Row>
                  <Col md="12" className="mb-2">

                    <Card>
                      <CardBody className="pt-2 pb-2 ps-3 pe-3">
                        <Row className="justify-content-between ">
                          <Col className="col-lg-4 mb-0">
                            <CardTitle className="mt-2"> Corporate Contact </CardTitle>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>


                    <Row>
                      {CorporateDetails.map((item, idx) => (
                        <Col className="col-md-3" key={idx}>
                          <Card className="mb-3">
                            <CardBody>
                              <div>
                                <div className="d-flex ">
                                  <div className="">
                                    <div className="maindatemeetingboxnew2">
                                      <img className='dashboard-profile-user' src="/static/media/avatar-1.c69c03fa.jpg"></img>
                                    </div>




                                  </div>
                                  <div >


                                    <p className="meetingheadingnotsmain"> {item.Name} </p>
                                    <div >

                                      <div className="d-flex">
                                        <div>
                                          <i className="las la-phone-volume clockiconedit"></i>
                                        </div>
                                        <div>
                                          <p className="meetingtimebox">{item.Mobilenumber}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div >

                                      <div className="d-flex">
                                        <div>
                                          <i className="las la-envelope clockiconedit"></i>
                                        </div>
                                        <div>
                                          <p className="meetingtimebox">{item.Email}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div >

                                      <div className="d-flex">

                                        <div>
                                          <i className="las la-briefcase locationiconnew"></i>
                                        </div>
                                        <div>
                                          <p className="locationmaindatatext">{item.Designation}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>


                  </Col>
                </Row> : ''} */}
              {LoginType == 'SubLogin' ?
                <Row>
                  <Col md="12" className="mb-2">
                    <Card className="mini-stats-wid ">
                      <CardBody>
                        <Row className="justify-content-between">
                          <Col className="col-lg-4 mb-0">
                            <CardTitle className="mt-2">Basic Information</CardTitle>
                          </Col>
                          <hr />
                        </Row>
                        <Row className="justify-content-between">
                          <hr />
                        </Row>
                        <Col className="col-lg-12 mb-2">
                          {CorporateDetails?.map((item, idx) => (
                            <Row className="mb-2" key={idx}>
                              <Col className="col-lg-3 col-md-4 col-12 mt-2" >
                                <label className="form-label">Name</label>
                                <Input type="text" className="form-control" readOnly defaultValue={item.Name} />
                              </Col>
                              <Col className="col-lg-3 col-md-4 col-12 mt-2">
                                <label className="form-label">Email Address</label>
                                <Input type="text" className="form-control" readOnly defaultValue={item.Email} />
                              </Col>
                              <Col className="col-lg-3 col-md-4 col-12 mt-2">
                                <label className="form-label">Contact Number</label>
                                <Input type="text" className="form-control" readOnly defaultValue={item.Mobilenumber} />
                              </Col>
                              <Col className="col-lg-3 col-md-12 col-12 mt-2">
                                <label className="form-label">Designation</label>
                                <Input type="text" className="form-control" readOnly defaultValue={item.Designation} />
                              </Col>
                            </Row>
                          ))}

                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> : ''}
              <Row>
                <Col md="12" className="mb-2">
                  <Card>
                    <CardBody className="pt-2 pb-2 ps-3 pe-3">

                      <Row className="justify-content-between ">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"> Meeting List </CardTitle>
                        </Col>

                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"><a href='/CorContactInfo/CorLeadMomList' className="viewlistbutton">View More</a></CardTitle>
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>
                  <Row>
                    {CorporateMeetingList.map((item, idx) => (
                      <Col className="col-md-3" key={idx}>
                        <Card className="h-100">
                          <CardBody>
                            <div>
                              <div className="d-flex mb-2">
                                <div className="">
                                  <div className="maindatemeetingbox">
                                    <p>{item.MeetingDay}</p>
                                    <p>{item.MeetingMonth}</p>
                                    <p>{item.MeetingYear}</p>
                                  </div>




                                </div>
                                <div >


                                  <p className="meetingheadingnotsmain"> {item.Meetingheadnote} </p>
                                  <div >

                                    <div className="d-flex">
                                      <div>
                                        <i className="las la-clock clockiconedit"></i>
                                      </div>
                                      <div>
                                        <p className="meetingtimebox">{item.Meetingtime}</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div >

                                    <div className="d-flex">
                                      <div>
                                        <i className="las la-map-marker locationiconnew"></i>
                                      </div>
                                      <div>
                                        <p className="locationmaindatatext">{item.Address}</p>
                                      </div>
                                    </div>
                                  </div>



                                </div>

                              </div>
                              {LoginType == 'MainLogin' ? <div>
                                <div className="d-flex align-items-center">
                                  <p className="boxmeetingtitle me-2">Attendee :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.Name}</p>
                                </div>
                                <hr className="mt-2 mb-2"></hr>
                              </div>
                                : ''}
                              <div>
                                <div className="d-flex align-items-center">
                                  <p className="boxmeetingtitle me-2">Meeting With :</p> <p className="pointstextedit mb-0 newmainwithtext">{item.MeetingWith}</p>
                                </div>
                                <hr className="mt-2 mb-2"></hr>
                              </div>
                              <div>
                                <p className="boxmeetingtitle">Agenda</p>
                                <hr className="mt-2 mb-2"></hr>
                                <div>
                                  {item.AgendaList.map((Aitem, idx1) => (
                                    idx1 == 0 ?
                                      <div className="d-flex" key={idx1}>
                                        <div>
                                          <i className="fa-solid fa-circle pointsimageedit"></i>
                                        </div>
                                        <div>
                                          <p className="pointstextedit">{Aitem.Agenda}</p>
                                        </div>
                                      </div> :
                                      idx1 == 1 ?
                                        <div className="d-flex" key={idx1}>
                                          <div>
                                            <i className="fa-solid fa-circle pointsimageedit"></i>
                                          </div>
                                          <div>
                                            <p className="pointstextedit">{Aitem.Agenda}</p>
                                          </div>
                                        </div> : ''
                                  ))}
                                </div>
                              </div>
                              <div>
                                <p className="boxmeetingtitle">Point</p>
                                <hr className="mt-2 mb-2"></hr>
                                <div>
                                  {item.PointList.map((Pitem, idx2) => (
                                    idx2 == 0 ?
                                      <div className="d-flex" key={idx2}>
                                        <div>
                                          <i className="fa-solid fa-circle pointsimageedit"></i>
                                        </div>
                                        <div>
                                          <p className="pointstextedit">{Pitem.Point}</p>
                                        </div>
                                      </div>
                                      : idx2 == 1 ?
                                        <div className="d-flex" key={idx2}>
                                          <div>
                                            <i className="fa-solid fa-circle pointsimageedit"></i>
                                          </div>
                                          <div>
                                            <p className="pointstextedit">{Pitem.Point}</p>
                                          </div>
                                        </div>
                                        : ''
                                  ))}
                                </div>
                              </div>
                              <div>
                                <p className="boxmeetingtitle">Meeting summary</p>
                                <hr className="mt-2 mb-2"></hr>
                                <div>
                                  <div className="d-flex">
                                    <p className="pointstextedit">{item.Meetingsummary}</p>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <button type="button" className="btn btn-sm btn-success mt-2 w-100" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e) => ViewMeetingDetails(e, item.MId)}><i className="las la-eye mr-2 eyeiconnew"></i> View</button>
                              </div>

                            </div>

                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );

}

export default Dashboard;
