import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { Card, Col, Row, CardTitle, CardBody } from "reactstrap";
import "react-perfect-scrollbar/dist/css/styles.css";

const GeneralDashboard = () => {
  const [SettingGeneralMenuList, setSettingGeneralMenuList] = useState([]);
  const [SettingHRMenuList, setSettingHRMenuList] = useState([]);
  const [SettingOtherMenuList, setSettingOtherMenuList] = useState([]);

  useEffect(() => {
    getSettingGeneralMenu();
    getSettingHRMenu();
    getSettingOtherMenu();
  }, [])

  const getSettingGeneralMenu = async () => {
    const VUserTypeId = localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId");
    const MenuFilterArr = {
      UserTypeId: VUserTypeId,
      MenuId: 0,
    }
    var AUrl = global.APIURL + '/SettingGeneralMenuList';
    axios.post(AUrl, MenuFilterArr).then(Sres => {
      let StateL = Sres.data.map((kcstate) => {
        return kcstate
      })
      setSettingGeneralMenuList(StateL);
    })
  }

  const getSettingHRMenu = async () => {
    const VUserTypeId = localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId");
    const MenuFilterArr = {
      UserTypeId: VUserTypeId,
      MenuId: 0,
    }
    var AUrl = global.APIURL + '/SettingHRMenuList';
    axios.post(AUrl, MenuFilterArr).then(Sres => {
      let StateL = Sres.data.map((kcstate) => {
        return kcstate
      })
      setSettingHRMenuList(StateL);
    })
  }

  const getSettingOtherMenu = async () => {
    const VUserTypeId = localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId");
    const MenuFilterArr = {
      UserTypeId: VUserTypeId,
      MenuId: 0,
    }
    var AUrl = global.APIURL + '/SettingOtherMenuList';
    axios.post(AUrl, MenuFilterArr).then(Sres => {
      let StateL = Sres.data.map((kcstate) => {
        return kcstate
      })
      setSettingOtherMenuList(StateL);
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | KC Admin Group</title>
        </MetaTags>
        <div className="container-fluid">
          <CardTitle className="mb-3">Settings</CardTitle>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
               
                

                  <div className="h5 mb-3">List</div>

                  <div className="text-center">
                    <ul className="list-unstyled megamenu-list2">

                     <Row>                      
                          <Col lg="2" className="mb-4 col-6" >
                            <li>
                              <a className="table-link-a" href='/CorContactInfo'> <i className="bx bxs-user-rectangle text-secondary"></i>
                              Corporate Contact Info</a>
                            </li>
                            
                            <li>
                              <a className="table-link-a" href='/Activities'> <i className="bx bxs-user-rectangle text-secondary"></i>
                              Activities</a>
                            </li>
                            </Col>                     
                      </Row>
                    </ul>
                  </div>
               </CardBody>
              </Card>
            </Col>

          </Row>
        </div>
      </div>

     
    </React.Fragment>
  );

}

export default GeneralDashboard;
