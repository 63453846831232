import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import Commheader from "../../../components/HorizontalLayout/Header";
import PlacholderImgC from "assets/images/favicon.png";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';


const Services = () => {
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Branchcode, setBranchcode] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
  const [NetworkSource, setNetworkSource] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkType, setNetworkType] = useState("");
  const [DistrictName,setDistrictName]=useState([]);
  const [TehsilName,setTehsilName]=useState([]);
  const [CityName,setCityName]=useState([]);
  
  
  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateName, setStateName] = useState("");
  const [EditcorLeadId, setcorEditLeadId] = useState("");


  const [Email, setEmail] = useState([]);
  const [Name, setName] = useState([]);
  const [MobileNo, setMobileNo] = useState([]);
  const [Designation, setDesignation] = useState([]);

  const [EmailIdd, setmEmailId] = useState([]);
  const [BureauList, setBureauList] = useState([]);
  const [CorporateServicesPackageList, setCorporateServicesPackageList] = useState([])
  const [BureauDataList,setBureauDataList]=useState([]);

  let CoLeadId = localStorage.getItem("CorporateleadId");

  let { id } = useParams();
 

  useEffect(() => {
   
    getServiceDetails();  
   
   
    getCorporateServicesPackageList();
  }, []);

const getServiceDetails=async()=>{
    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const SData = {
        "Id": VCorporateleadId
      }
  
    axios.post(global.APIURL + '/CorporateleadclientDetails ', SData)
    .then(res => {
      
      if(res.data.Result[0].length>0){
        setNetworkSource(res.data.Result[0].ClientDetails[0].CompanyName);
        setNetworkType(res.data.Result[0].ClientDetails[0].ChannelTypeName);
        setAddress(res.data.Result[0].ClientDetails[0].Address);
        setStateName(res.data.Result[0].ClientDetails[0].StateName);
        setDistrictName(res.data.Result[0].ClientDetails[0].districtName);
        setTehsilName(res.data.Result[0].ClientDetails[0].TehsilName);
        setCityName(res.data.Result[0].ClientDetails[0].CityName);        
        setPincode(res.data.Result[0].ClientDetails[0].Pincode);
        setBranchcode(res.data.Result[0].ClientDetails[0].Branchcode);
        setmEmailId(res.data.Result[0].ClientDetails[0].EmailId);
        setLeadContactList(res.data.Result[0].de);  
      
      }
   
    });


}

 
  const getCorporateServicesPackageList = async () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const CorporateData = {
      Id: VCorporateleadId,
    }
    axios.post(global.APIURL + '/CorporateServicesPackage', CorporateData)
      .then(res => {
        console.log("res",res.data);
       
        setCorporateServicesPackageList(res.data);
        
      });
  }

  
  const editRecord = (SerPackageId) => {
    
    let id = SerPackageId
    localStorage.setItem("CorporateServiceId", SerPackageId)
    let path = `Services/SerPackageDetail/:${id}`;
    window.location.href=path
  }
  
  const exportPDF=()=>{

    const unit = "pt";
    const size = "A2"; 
    const orientation = "portrait"; 

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Category List Report";
    const headers = [["#","Category"]];

    // const data = records.map((item,index)=> [index+1,item.Title]);

    // let content = {
    //   startY: 50,
    //   head: headers,
    //   body: data
    // };

    // doc.text(title, marginLeft, 40);
    // doc.autoTable(content);
    // doc.save("CategoryReport.pdf")
  }
const ManageServiceActivitiesRecord = (SerPackageId, CorleadId) => {

    localStorage.setItem("CorporateLeadId", CorleadId)
    localStorage.setItem("CorporateServiceId", SerPackageId)
    let path = `CorporateClientServiceActivities`;
   // let npath=path.replace('EditRegisterClient/','')
    history.push(path);
  }
  return (
    <React.Fragment>
      <Commheader />
      <div className="page-content">
        <MetaTags>
          <title>Corporate Service Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">

        <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Services Package</p>
              
                  <div className='maindasboardtableeditnew'>
                          <table className="table mt-0 mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col">Services Package</th>
                                <th scope="col" className='text-center'>Type</th>
                                <th scope="col" className='text-center'>Price</th>
                                <th scope="col" className='text-center'>Total Amount</th>
                                <th scope="col" className='text-center'>Recived Amount</th>
                                <th scope="col" className='text-center'>Due Amount</th>
                                <th scope="col" className='text-center'>Action</th>                                                      
                              </tr>
                            </thead>
                            <tbody>
                              {CorporateServicesPackageList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td>{item.ServiceTitle}</td>
                                  <td className='text-center'>{item.ServicesPackageType}</td>
                                  <td className='text-center'>{item.PlanAmount}</td>
                                  <td className='text-center'>{item.TotalAmount}</td>
                                  <td className='text-center'>{item.TotalRecivedAmount}</td>
                                  <td className='text-center'>{item.TotalDueAmount}</td>
                                  <td className='text-center'><a  onClick={(editRecord.bind(this,item.ServicesPackageId))}className="btn btn-primary btn-sm font-14 btncolor" >Manage Bureau</a>                        
                                  {/* <a onClick={(ManageServiceActivitiesRecord.bind(this, item.ServicesPackageId, item.CorporateleadId))} className="btn btn-primary btn-sm font-14 btncolor" > Manage Bureau</a>
                            */}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                </div>

              </div>
            </div>

          </div>

       
        </div>
      </div>
    </React.Fragment>
  );
};

export default Services;
