import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import Commheader from "../../../components/HorizontalLayout/Header";
import PlacholderImgC from "assets/images/favicon.png";


const ManageMom = () => {
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Branchcode, setBranchcode] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
  const [CompanyId, setCompanyId] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkTypeId, setNetworkTypeId] = useState("");
  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateId, setStateId] = useState("");
  const [EditcorLeadId, setcorEditLeadId] = useState("");
  const [NextFollowupDate, setNextFollowupDate] = useState('');
  const [NextFollowupTime, setNextFollowupTime] = useState('');
  const [NextFollowupMode, setNextFollowupMode] = useState('');
  const [NextFollowupNote, setNextFollowupNote] = useState('');
  const [CorporateleadContactId, setCorporateleadContactId] = useState("");

  const [ConvertDate, setConvertDate] = useState('');
  const [ConvertRemark, setConvertRemark] = useState('');

  const [CloseDate, setCloseDate] = useState('');
  const [CloseRemark, setCloseRemark] = useState('');

  const [Email, setEmail] = useState([]);
  const [Name, setName] = useState([]);
  const [MobileNo, setMobileNo] = useState([]);
  const [Designation, setDesignation] = useState([]);

  const [LeadEmailList, setLeadEmailList] = useState([]);

  const [MeetNote, setMeetNote] = useState([]);
  const [MeetSummary, setMeetSummary] = useState([]);
  const [MeetDate, setMeetDate] = useState([]);
  const [MeetTime, setMeetTime] = useState([]);
  const [NextMeetDate, setNextMeetDate] = useState([]);
  const [MeetPointList, setMeetPointList] = useState([]);
  const [MeetAgendaList, setMeetAgendaList] = useState([]);
  const [AttachmentList, setAttachmentList] = useState([]);
  const [AttachmentFilePath, setAttachmentFilePath] = useState([]);
  const [AttachmentFile, setAttachmentFile] = useState([]);
  const [MeetDetails, setMeetDetails] = useState([]);
  const [DisplayAttachFile, setDisplayAttachFile] = useState([]);

  const [MeetPoint, setMeetPoint] = useState([]);
  const [MeetAgenda, setMeetAgenda] = useState([]);
  const [MediaFileName, setMediaFileName] = useState([]);
  const [MediaPath, setMediaPath] = useState([]);
  const [pointde, setpoint] = useState([]);
  const [agendade, setagenda] = useState([]);
  const [att, setttache] = useState([]);
  const [EmailIdd, setmEmailId] = useState([]);

  let { id } = useParams();
 


  let CorporateId = id.split(':');
  const CoLeadId = CorporateId[1];

  let MIdstorage = localStorage.getItem("MId");

  useEffect(() => {
    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');

        getStateList("1");
        getLeadDetailById(newid[1]);
        getCompanyList();
        getNetWorkList();
        getMenuHideShow();
        getLeadContactList(newid[1]);
        getMeetDetailList(newid[1]);
        getEditMeetPointDetails(newid[1]);
        getEditMeetAgendaDetails(newid[1]);
        getpointList(newid[1]);
        getagendaList(newid[1]);
        getAttachmentList(newid[1]);
      }
    }
  }, []);

  const getMenuHideShow = async () => {
    const f = {
      MenuId: "48",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add Tehsil') {
            setAddTehsil(MS);
          }
          else if (MA == 'Add City') {
            setAddCity(MS);
          }
        }
      });
  };
  const getpointList = async (CID) => {
    const MData = {
      "CorporateleadId": CID,
      "MId": MIdstorage
    }
   
    axios.post(global.APIURL + '/pointinstweb', MData)
      .then(res => {
      
        setpoint(res.data);

      });


  }
  const getagendaList = async (CID) => {
    const MData = {
      "CorporateleadId": CID,
      "MId": MIdstorage
    }
  
    axios.post(global.APIURL + '/Agendainstweb', MData)
      .then(res => {
    
        setagenda(res.data);

      });


  }

  const getAttachmentList = async (CID) => {
    const MData = {
      "CorporateleadId": CID,
      "MId": MIdstorage
    }

    axios.post(global.APIURL + '/Attachmentweb', MData)
      .then(res => {
       
        setttache(res.data);

      });


  }
  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };



  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };

  const getStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setStateList(StateL);
      });
  };

  const getDistrictList = async SId => {
    setStateId(SId);
  
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {

        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setDistrictList(DistrictL);
      });
  };


  const getCompanyTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setTehsilList(TehsilL);
      });
  };

  const getLeadDetailById = async LId => {
   
    var AUrl = global.APIURL + '/CorporateleadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
      

        setCompanyId(res.data[0].CompanyId);
        setNetworkTypeId(res.data[0].ChannelTypeId);
        setAddress(res.data[0].Address);
        setStateId(res.data[0].StateId);
        getDistrictList(res.data[0].StateId);
        setCompanyDistrictId(res.data[0].DistrictId);
        getCompanyTehsilList(res.data[0].DistrictId);
        setCompanyCityId(res.data[0].CityId);
        setCompanyTehsilId(res.data[0].TehsilId);
        getCityList(res.data[0].TehsilId)
        setPincode(res.data[0].Pincode);
        setBranchcode(res.data[0].Branchcode);
        setcorEditLeadId(res.data[0].CorporateleadId);
        setCorporateleadContactId(res.data[0].CorporateleadContactId);
        setmEmailId(res.data[0].EmailId);

      });
  };

  const getLeadContactList = async (CId) => {



    var AUrl = global.APIURL + '/CorporateleadcontactEditClient/' + CId;

    axios.post(AUrl)
      .then(res => {
       
        setEmail(res.data[0].Email);
        setName(res.data[0].Name);
        setMobileNo(res.data[0].Mobilenumber);
        setDesignation(res.data[0].Designation);

      });
  }

  const getCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCityList(CityL);
      });
  };


  const getMeetDetailList = async (CID) => {
    const MData = {
      "CorporateleadId": CID,
      "MId": MIdstorage
    }

    axios.post(global.APIURL + '/WebCorporateLeadMomListWeb', MData)
      .then(res => {
       
        setMeetDetails(res.data);
       


      });

  }


  const MeetPointhandleAddRow = e => {
    var LRNo = MeetPointList.length == 0 ? 1 : (MeetPointList[MeetPointList.length - 1].RowId + 1);

    var VMeetPoint = document.getElementById('txtMeetingPoint').value;

    const Mitem = {
      MeetPointId: 0,
      RowId: LRNo,
      Point: VMeetPoint,
      MeetPointTextBoxControlId: 'txtMeetingPoint' + LRNo,

    };
    setMeetPointList([...MeetPointList, Mitem]);
    document.getElementById('txtMeetingPoint').value = '';

  };

  const MeetPointhandleRemoveRow = (e, RowId, MeetPointId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetPointList(MeetPointList.filter(item => item.RowId !== RId));

      var VMeetPointId = parseInt(MeetPointId == null ? '0' : MeetPointId == '' ? '0' : MeetPointId);
      if (VMeetPointId > 0) {
        const MPItemArr = { Id: VMeetPointId }
        axios.post(global.APIURL + '/WebMOMPointDelete', MPItemArr).then(resem => { });
      }
    }
  };

  const MeetAgendahandleAddRow = e => {
    var LRNo = MeetAgendaList.length == 0 ? 1 : (MeetAgendaList[MeetAgendaList.length - 1].RowId + 1);

    var VAgenda = document.getElementById('txtMeetingAgenda').value;

    const Aitem = {
      MeetAgendaId: 0,
      RowId: LRNo,
      Agenda: VAgenda,
      MeetAgendaTextBoxControlId: 'txtMeetingAgenda' + LRNo,

    };
    setMeetAgendaList([...MeetAgendaList, Aitem]);
    document.getElementById('txtMeetingAgenda').value = '';

  };

  const MeetAgendahandleRemoveRow = (e, RowId, MeetAgendaId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetAgendaList(MeetAgendaList.filter(item => item.RowId !== RId));

      var VMeetAgendaId = parseInt(MeetAgendaId == null ? '0' : MeetAgendaId == '' ? '0' : MeetAgendaId);
      if (VMeetAgendaId > 0) {
        const MAItemArr = { Id: VMeetAgendaId }
        axios.post(global.APIURL + '/WebMOMagendaDelete', MAItemArr).then(resem => { });
      }
    }
  };


  const AttachmenthandleAddRow = e => {
    var LRNo = AttachmentList.length == 0 ? 1 : (AttachmentList[AttachmentList.length - 1].RowId + 1);
    var VAttachFile = AttachmentFile;
    var VAttachFilePath = AttachmentFilePath;

    const ALitem = {
      AttachId: 0,
      RowId: LRNo,
      AttachFile: VAttachFile,
      AttachFilePath: VAttachFilePath,
      UploadFileTextBoxControlId: "AttachfileId" + LRNo

    };
    setAttachmentList([...AttachmentList, ALitem]);
    document.getElementById("AttachfileId").value = "";


  };

  const AttachmenthandleRemoveRow = (e, RowId, AttachId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setAttachmentList(AttachmentList.filter(item => item.RowId !== RId));

      var VAttachId = parseInt(AttachId == null ? '0' : AttachId == '' ? '0' : AttachId);
      if (VAttachId > 0) {
        const AItemArr = { Id: VAttachId }
        axios.post(global.APIURL + '/WebMOMAttachDelete', AItemArr).then(resem => { });
      }
    }
  };



  function AttachmentfetchPost(e) {
    var details = e.target.files[0];
    var details1 = "MeetAttachment";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {

        setAttachmentFile(res.data.FileName);
        setAttachmentFilePath(res.data.IName);
      

        if (e.target.files[0].name != null) {
          if (e.target.files[0].name != '') {
            setDisplayAttachFile(1);
          }
          else {
            setDisplayAttachFile(0);
          }
        }
      });
  }

  const getEditMeetPointDetails = async (CId) => {
    const Mdata = {

      "CorporateleadId": CId

    }
    axios.post(global.APIURL + '/WebCorporateLeadMompointEdit', Mdata).then(res => {

     
      if (res.data.length > 0) {
        setMeetPoint(res.data[0].Point);
      }
    });
  }

  const getEditMeetAgendaDetails = async (CId) => {
    const MAdata = {

      "CorporateleadId": CId

    }
    axios.post(global.APIURL + '/WebCorporateLeadMomAgendaEdit', MAdata).then(res => {

     
      if (res.data.length > 0) {
        setMeetAgenda(res.data[0].Agenda);
      }

    });




  }



  const saveMeetDetails = async () => {

    const MeetData = {
      Meetingheadnote: MeetNote,
      Meetingsummary: MeetSummary,
      MeetingDate: MeetDate,
      Meetingtime: MeetTime,
      Nextmeetingdate: NextMeetDate,
      "Flag": "A",
      CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),
      CorporateleadId: CoLeadId
    }
    axios.post(global.APIURL + '/WebCorporateLeadManageofmeetingAdd', MeetData).then(res => {
      var VMId = res.data[0].MId;
      for (let mpl = 0; mpl < MeetPointList.length; mpl++) {
        const MeetPointDetail = MeetPointList[mpl];
  
        var VMeetPointTextBoxControlId = MeetPointDetail.MeetPointTextBoxControlId;
        var VMeetPoint = document.getElementById(VMeetPointTextBoxControlId).value == null ? '' : document.getElementById(VMeetPointTextBoxControlId).value;
  
        if (VMeetPoint != '') {
          const MeetItemArr = {
            MId: VMId,
            Point: VMeetPoint,
            "Flag": "A",
            CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),
            CorporateleadId: CoLeadId
          };
  
          axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => {
  
         
          });
        }
      }
  
      for (let mal = 0; mal < MeetAgendaList.length; mal++) {
        const MeetAgendaDetail = MeetAgendaList[mal];
  
        var VMeetAgendaTextBoxControlId = MeetAgendaDetail.MeetAgendaTextBoxControlId;
        var VMeetAgenda = document.getElementById(VMeetAgendaTextBoxControlId).value == null ? '' : document.getElementById(VMeetAgendaTextBoxControlId).value;
  
        if (VMeetAgenda != '') {
          const MeetAgItemArr = {
            MId: VMId,
            Agenda: VMeetAgenda,
            "Flag": "A",
            CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),
            CorporateleadId: CoLeadId
          };
         
  
          axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => {
  
           
          });
        }
      }
  
      for (let al = 0; al < AttachmentList.length; al++) {
        const AttachmentListDetail = AttachmentList[al];
  
        var VAttachment = AttachmentListDetail.AttachFilePath;
        var VmediafileName = AttachmentListDetail.AttachFile;
  
        if (VAttachment != '') {
          const ALItemArr = {
            MId: VMId,
            Attachment: VAttachment,
            mediafileName: VmediafileName,
            "Flag": "A",
            CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),
            CorporateleadId: CoLeadId
          };
         
  
          axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => {
  
           
          });
        }
      }
    
      alert("Insert Succefully");
    });



  

  }

  return (
    <React.Fragment>
      <Commheader />
      <div className="page-content">
        <MetaTags>
          <title>Corporate Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">

          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Corporate Detail</CardTitle>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/CorContactInfo/CorLeadMomList">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2">
                        <CardTitle>Corporate Contact Detail</CardTitle>
                        <hr />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Company </p>
                        <select className="form-select" id="ddlCompany" name="ddlCompany" onChange={e => { setCompanyId(e.target.value) }}>
                          <option key="0" value="0">Select Company</option>
                          {CompanyList.map(item => (
                            <option key={item.CompanyId} value={item.CompanyId} selected={CompanyId === item.CompanyId}>
                              {item.CompanyName}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Type </p>
                        <select className="form-select" id="ddlNetworktype" name="ddlNetworktype" onChange={e => { setNetworkTypeId(e.target.value) }}>
                          <option key="0" value="0">Select Network Type</option>
                          {NetworkTypeList.map(item => (
                            <option key={item.ChannelTypeId} value={item.ChannelTypeId} selected={NetworkTypeId === item.ChannelTypeId}>
                              {item.ChannelTypeName}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col>
                        <div className="col-lg-3 col-md-6 col-12 mt-3">
                          <label htmlFor="EmailId" className="form-label">
                            EmailId
                          </label>
                          <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={EmailIdd}
                            onChange={e => setmEmailId(e.target.value)}
                          />

                        </div>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                        <p className="mt-3 mb-2 fontWeight500"> Address </p>
                        <div className="input-group auth-pass-inputgroup">
                          <textarea className="form-control" placeholder="Address" id="txtIndAddress" rows="3" name="txtIndAddress" defaultValue={Address} onChange={e => setAddress(e.target.value)}></textarea>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> State </p>
                        <select className="form-select" id="ddlPerState" name="ddlPerState" onChange={e => { getDistrictList(e.target.value); setStateId(e.target.value) }}>
                          <option key="0" value="0">Select State</option>
                          {StateList.map(item => (
                            <option key={item.StateId} value={item.StateId} selected={StateId === item.StateId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> District </p>
                        <select className="form-select" Id="ddlPerDistrictId" name="ddlPerDistrictId" onChange={e => { getCompanyTehsilList(e.target.value); setCompanyDistrictId(e.target.value) }}>
                          <option key="0" value="0">Select District</option>
                          {DistrictList.map(item => (
                            <option key={item.DistrictId} value={item.DistrictId} selected={CompanyDistrictId === item.DistrictId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                          </div>
                          <div>
                            {AddTehsil == "A" ? DistrictId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerTehsil">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" id="ddlPerTehsil" name="ddlPerTehsil" onChange={e => { getCityList(e.target.value); setCompanyTehsilId(e.target.value) }}>
                          <option key="0" value="0">Select Tehsil</option>
                          {TehsilList.map(item => (
                            <option key={item.TehsilId} value={item.TehsilId} selected={CompanyTehsilId === item.TehsilId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                          </div>
                          <div>
                            {AddCity == "A" ? TehsilId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerCity">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" id="ddlPerCity" name="ddlPerCity" onChange={e => setCityId(e.target.value)}>
                          <option key="0" value="0">Select City/Village/Town</option>
                          {CityList.map(item => (
                            <option key={item.CityId} value={item.CityId} selected={CompanyCityId === item.CityId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="text" className="" id="txtPinCode" defaultValue={Pincode} onChange={e => setPincode(e.target.value)} placeholder="Enter PinCode" />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Branch Code </p>
                        <Input type="text" className="" id="txtBranchCode" defaultValue={Branchcode} onChange={e => setBranchCode(e.target.value)} placeholder="Enter Branch Code" />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="">
                      <Col className="col-lg-12 mb-2">
                        <CardTitle>Contact Information</CardTitle>
                        <hr />
                      </Col>
                      <Col lg="12">
                        <table style={{ width: "100%" }}>
                          <tbody>

                          </tbody>
                        </table>
                        <div data-repeater-list="group-a">
                          <Row data-repeater-item className="align-items-end">
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Name
                              </Label>
                              <Input type="text" id="txtName" className="form-control" placeholder="Enter Name" defaultValue={Name} />
                            </Col>
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Email
                              </Label>
                              <Input type="text" id="txtEmail" className="form-control" placeholder="Enter Email" defaultValue={Email} />
                            </Col>
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Mobile No
                              </Label>
                              <Input type="text" id="txtMobilenumber" className="form-control" placeholder="Enter Mobile No" defaultValue={MobileNo} />
                            </Col>
                            <Col lg="2" className="">
                              <Label htmlFor="name">
                                Designation
                              </Label>
                              <Input type="text" id="txtDesignation" className="form-control" placeholder="Enter Designation" defaultValue={Designation} />
                            </Col>

                            <Col lg="1" className="mt-3">

                            </Col>

                          </Row>
                        </div>
                      </Col>

                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="">
                      <Col className="col-lg-12 mb-2">
                        <CardTitle>Meeting Information</CardTitle>
                        <hr />
                      </Col>
                      <Row className="align-items-center">
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Headnote</Label>
                          <Input type="text" id="txtMeetNote" className="form-control" placeholder="Enter Meeting HeadNote" onChange={e => setMeetNote(e.target.value)} defaultValue={MeetNote} />
                        </Col>
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Summary</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input type="text" id="txtMeetSummary" className="form-control" placeholder="Enter Meeting Summary" onChange={e => setMeetSummary(e.target.value)} defaultValue={MeetSummary} /> </div>
                        </Col>
                      </Row>
                      <Row className="">
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {MeetPointList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Meeting Points</Label>
                                          <Input type="text" id={item.MeetPointTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Point} />
                                        </Col>

                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            MeetPointhandleRemoveRow(e, item.RowId, item.MeetPointId)
                                          }>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Meeting Points</Label>
                                <Input type="text" id="txtMeetingPoint" className="form-control" placeholder="Enter Meeting Points" defaultValue={MeetPoint} />
                              </Col>
                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={MeetPointhandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {AttachmentList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Attachment</Label>
                                          <span className="form-control form-control"><i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i><a href={item.AttachFilePath} target="_blank" rel="noopener noreferrer" >{item.AttachFile}</a></span>
                                          <input type="hidden" className="form-control form-control" id={item.UploadFileTextBoxControlId} defaultValue={item.AttachFilePath} />
                                        </Col>
                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            AttachmenthandleRemoveRow(e, item.RowId, item.AttachId)
                                          }>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Attachment</Label>

                                {DisplayAttachFile > 0 ?
                                  <span className="float-end text-primary font-12" id="spandoctitle">
                                    <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                    <a href={AttachmentFilePath} target="_blank" rel="noopener noreferrer" >{AttachmentFile}</a></span> : <span className="float-end text-primary font-12" id="spandoctitle"><i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true">
                                    </i><a href={MediaPath} target="_blank" rel="noopener noreferrer" >{MediaFileName}</a>
                                  </span>}



                                <input type="file" className="form-control form-control" id="AttachfileId" onChange={AttachmentfetchPost} />

                              </Col>

                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={AttachmenthandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {MeetAgendaList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Next Meeting Agenda</Label>
                                          <Input type="text" id={item.MeetAgendaTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Agenda} />
                                        </Col>

                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            MeetAgendahandleRemoveRow(e, item.RowId, item.MeetAgendaId)}>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Next Meeting Agenda</Label>
                                <Input type="text" id="txtMeetingAgenda" className="form-control" placeholder="Enter Meeting Agenda" defaultValue={MeetAgenda} />
                              </Col>
                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={MeetAgendahandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Date</Label>
                          <Input type="date" id="txtMeetDate" className="form-control" placeholder="Enter Meeting Date" onChange={e => setMeetDate(e.target.value)} defaultValue={MeetDate} />
                        </Col>
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Time</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <input id="txtMeetTime" type="time" className="form-control" placeholder="Enter Meeting Time" onChange={e => setMeetTime(e.target.value)} defaultValue={MeetTime} />
                          </div>
                        </Col>
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Next Meeting Date</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <input id="txtNextMeetDate" type="date" className="form-control" placeholder="Enter Next Meeting Date" onChange={e => setNextMeetDate(e.target.value)} defaultValue={NextMeetDate} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="col-lg-12">
                          <button
                            type="button"
                            className="btn btn_success me-2"
                            onClick={e => saveMeetDetails()}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn_danger"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Meeting Details List</CardTitle>
                        <hr />
                      </Col>
                      <Col className="col-lg-12 col-md-12 mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="text-center" scope="col">#</th>
                              <th className="text-center" scope="col">Meeting headnote</th>
                              <th className="text-center" scope="col">Meeting summary</th>
                              <th className="text-center" scope="col">Meeting Date</th>
                              <th className="text-center" scope="col">Meeting Time</th>
                              <th className="text-center" scope="col">Next Meeting date</th>
                              <th className="text-center" scope="col">Point</th>
                              <th className="text-center" scope="col">Agenda</th>
                              <th className="text-center" scope="col">Attachment</th>

                           
                              {/* <th className="text-center" scope="col">Action</th> */}

                            </tr>
                          </thead>
                          <tbody>
                            {MeetDetails.map((record, index) => {
                              return (
                                <tr key={record.RowNo}>
                                  <td className="text-center">{record.Srno}</td>
                                  <td className="text-center">{record.Meetingheadnote}</td>
                                  <td className="text-center">{record.Meetingsummary}</td>
                                  <td className="text-center">{record.MeetingDate}</td>
                                  <td className="text-center">{record.Meetingtime}</td>
                                  <td className="text-center">{record.Nextmeetingdate}</td>
                                  <td className="text-center position-relative"><p className="mb-0" >{record.Point}</p>
                                    <small className="text-primary dropdown-toggle " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">View More</small>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                      {/* <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                      {pointde.map((re, index) => { return (<li  className="dropdown-item" key={re.mpoint}>{re.Point}</li>) })}
                                    </ul>

                                  </td>
                                  <td className="text-center position-relative"><p className="mb-0" >{record.Agenda}</p>
                                    <small className="text-primary dropdown-toggle " id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">View More</small>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                      {/* <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                      {agendade.map((re, index) => { return (<li  className="dropdown-item" key={re.MAId}>{re.Agenda}</li>) })}
                                    </ul>

                                  </td>
                                  
                                  <td>
                                    {att.map((ree, index) => { return (<li className="dropdown-item" key={ree.MAId}><span className="float-end text-primary font-12" id="spandoctitle">
                                    <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                    <a href={ree.Attachment} target="_blank" rel="noopener noreferrer" >{ree.MediaFileName}</a></span></li>)})}
                                    </td>

                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

              </Col>

            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageMom;
