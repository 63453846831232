import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'


import axios from 'axios'
//import Apaexlinecolumn from "pages/AllCharts/apex/apaexlinecolumn";
import ReactApexChart from "react-apexcharts"
import e from 'cors';
const BureauHelp = () => {

    const [BureauCategoryList, setBureauCategoryList] = useState([])
    const [BureauSubCategoryList, setBureauSubCategoryList] = useState([])
    const [BureauHelpList, setBureauHelpList] = useState([])

    useEffect(() => {
        getBureauHelpData();
    }, []);

    const getBureauHelpData = () => {
        const CorporateData = { Id: '0' }
        axios.post(global.APIURL + '/CorporateBureauHelpList', CorporateData)
            .then(res => {
                setBureauCategoryList(res.data.Result[0].BureauCategory);
                setBureauSubCategoryList(res.data.Result[0].BureauSubCategory);
                setBureauHelpList(res.data.Result[0].BureauHelp);
        
            });

            
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | KC Admin Group</title>
                </MetaTags>
                <div className="container-fluid">
                    <Row className="align-items-center justify-content-between mb-3">
                        <div className="col-lg-8">
                            <CardTitle className="mb-0">Bureau Help</CardTitle>
                        </div>
                    </Row>

                    <Row>
                        {BureauCategoryList.map((item, idx) => (
                            <Col className="col-4  " key={idx}>
                                <Card className="heighfixdashbiardnew">
                                    <CardBody>
                                        <Row>
                                            <div className="col-lg-12 mb-2 col"><div className="card-title newtitaleeditburee"> {item.Title}  </div></div>
                                            <Col xl="12">
                                                {BureauSubCategoryList.filter(x => x.BId == item.BId).map((item1, idx1) => (
                                                    <div className="mainnewcategeroiesbureu mb-3" key={idx1}>
                                                        <div className="maincategeriesnamebox ps-2 pe-2 pt-2 pb-2 ">
                                                            <h6 className="mb-0 maincategeriesnamenewedit">{item1.Title}</h6>
                                                        </div>
                                                        <div className="maindatauserbuera">
                                                            <div className="maindatdestailsbureau" >
                                                                <div className="">
                                                                    <p className="maintitlebureau">#</p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="maintitlebureau">Communication Level</p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="maintitlebureau">Name</p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="maintitlebureau">Email</p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="maintitlebureau">Mobile No</p>
                                                                </div>

                                                            </div>
                                                            {BureauHelpList.filter(x => x.BId == item.BId && x.BsubId == item1.BsubId).map((item2, idx2) => (
                                                                <div className="maindatdestailsbureau mt-2" key={idx2}>
                                                                    <div className="">
                                                                        <h6 className="mb-0">{idx2 + 1}</h6>
                                                                    </div>
                                                                    <div className="">
                                                                        <h6 className="mb-0">{item2.CLevel}</h6>
                                                                    </div>
                                                                    <div className="">
                                                                        <h6 className="mb-0">{item2.Name}</h6>
                                                                    </div>
                                                                    <div className="">
                                                                        <h6 className="mb-0">{item2.EmailId}</h6>
                                                                    </div>
                                                                    <div className="">
                                                                        <h6 className="mb-0">{item2.ContactNo}</h6>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );

}

export default BureauHelp;
