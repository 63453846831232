import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import Commheader from "../../../components/HorizontalLayout/Header";
import LinkApp from 'App';



const OnSort = () => {
  let columns = [
    {
      key: "SR",
      text: "#",
      sortable: true
    },
    {
      key: "Name",
      text: "Name",
      sortable: true,
      cell: (record, index) => {
        let Name = record["Name"];
        let CorContactId=record["CorporateleadContactId"];
       
        return (
          <Fragment>
            <a className="me-3 text-primary"  onClick={(editRecord.bind(this,record))}>{Name}</a>
          </Fragment>
        );
      }
    },
    {
      key: "Email",
      text: "Email",
      sortable: true
    },
    {
      key: "Mobilenumber",
      text: "Mobile number",
      sortable: true
    },
    {
      key: "CompanyName",
      text: "Company Name",
      sortable: true
    },
    {
      key: "ChannelTypeName",
      text: "Channel Type ",
      sortable: true
    },
    {
      key: "LeadStatus",
      text: "Lead Status",
      sortable: true
    },
   
  ];  
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    // button: {
    //     excel: true,
    //     print: true,
    //     csv: true
    // }
  }
  const [records, setRecords] = useState([])
  const [CreditStatusFlag, setCreditStatusFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");

  const [CorLeadId,setCorLeadId]=useState([]);
  const [CorporateContactId,setCorporateContactId]=useState([]);
  const [CorporateDetails,setCorporateDetails]=useState([]);
 
  let history = useHistory();

  useEffect(() => {
    getCorporateContactInfo();
 
  }, []);

  const getCorContactId=(CorContactId)=>{
    localStorage.setItem("CorContactId",CorContactId);
  }



  const getCorporateContactInfo=async()=>{

    let VCorporateleadId=localStorage.getItem("CorporateleadId");
    let VCorporateleadContactId=localStorage.getItem("CorporateleadContactId");
    let VCorporateLead=localStorage.getItem("CorporateLead");

   if(VCorporateLead=="YES")
    {
      const CorporateData={
        CorLeadId:"",
        CorInformationLeadId:VCorporateleadContactId
      }

      axios.post(global.APIURL + '/CorporateContactInfo', CorporateData)
      .then(res => {
      
                  
            setRecords(res.data);
             
      });
      
    }
    else if(VCorporateLead=="")
    {
    
      const CorporateData={
        CorLeadId:VCorporateleadId,
        CorInformationLeadId:""
      }

      axios.post(global.APIURL + '/CorporateContactInfo', CorporateData)
      .then(res => {
    
                 
           setRecords(res.data);            
      });
   
    }   
  }
  
  const editRecord = (record) => {
    let id = record["CorporateleadContactId"]
    let path = `/CorContactInfo/CorContactInfoDetail/:${id}`;
    history.push(path);
  }

 
  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  return (
    <React.Fragment>
  <Commheader/>
    <div className="page-content">
      <MetaTags>
        <title>Corporate Contact | KC Admin Group</title>
      </MetaTags>
      <div className="container-fluid">
         
        <Row className="align-items-center justify-content-between mb-3">
          <div className="col-lg-8">
            <CardTitle className="mb-0">Corporate Contact</CardTitle>
          </div>     
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
     
       
      </div>
    </div>

  </React.Fragment>
  );
}

export default OnSort;