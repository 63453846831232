import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import Commheader from "../../../components/HorizontalLayout/Header";
import PlacholderImgC from "assets/images/favicon.png";


const ManageInformation = () => {
  
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Branchcode, setBranchcode] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
  const [NetworkSource, setNetworkSource] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkType, setNetworkType] = useState("");
  const [DistrictName,setDistrictName]=useState([]);
  const [TehsilName,setTehsilName]=useState([]);
  const [CityName,setCityName]=useState([]);
  
  
  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateName, setStateName] = useState("");
  const [EditcorLeadId, setcorEditLeadId] = useState("");


  const [Email, setEmail] = useState([]);
  const [Name, setName] = useState([]);
  const [MobileNo, setMobileNo] = useState([]);
  const [Designation, setDesignation] = useState([]);

  const [MeetNote, setMeetNote] = useState([]);
  const [MeetSummary, setMeetSummary] = useState([]);
  const [MeetDate, setMeetDate] = useState([]);
  const [MeetTime, setMeetTime] = useState([]);
  const [NextMeetDate, setNextMeetDate] = useState([]);
  const [MeetPointList, setMeetPointList] = useState([]);
  const [MeetAgendaList, setMeetAgendaList] = useState([]);
  const [AttachmentList, setAttachmentList] = useState([]);
  const [AttachmentFilePath, setAttachmentFilePath] = useState([]);
  const [AttachmentFile, setAttachmentFile] = useState([]);
  const [MeetDetails, setMeetDetails] = useState([]);
  const [DisplayAttachFile, setDisplayAttachFile] = useState([]);
  const [pointDetails, setpointDetails] = useState([]);
  const [MeetPoint, setMeetPoint] = useState([]);
  const [pointde, setpoint] = useState([]);
  const [agendade, setagenda] = useState([]);
  //const [mid, setMid] = useState([]);
  const [EmailIdd, setmEmailId] = useState([]);
  const [BureauList, setBureauList] = useState([]);

  

  let CoLeadId = localStorage.getItem("CorporateleadId");

  let { id } = useParams();


  useEffect(() => {
   
    getServiceDetails();  
    getCompanyList();
    getNetWorkList();
    getBureauList();
  }, []);

const getServiceDetails=async()=>{
    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const SData = {
        "Id": VCorporateleadId
      }
  
    axios.post(global.APIURL + '/CorporateleadclientDetails ', SData)
    .then(res => {
   
      setNetworkSource(res.data.Result[0].ClientDetails[0].CompanyName);
      setNetworkType(res.data.Result[0].ClientDetails[0].ChannelTypeName);
      setAddress(res.data.Result[0].ClientDetails[0].Address);
      setStateName(res.data.Result[0].ClientDetails[0].StateName);
      setDistrictName(res.data.Result[0].ClientDetails[0].districtName);
      setTehsilName(res.data.Result[0].ClientDetails[0].TehsilName);
      setCityName(res.data.Result[0].ClientDetails[0].CityName);        
      setPincode(res.data.Result[0].ClientDetails[0].Pincode);
      setBranchcode(res.data.Result[0].ClientDetails[0].Branchcode);
      setmEmailId(res.data.Result[0].ClientDetails[0].EmailId);
      setLeadContactList(res.data.Result[0].de);  
    
    });


}

const getBureauList=async()=>{

    
    axios.get(global.APIURL + '/bureaucategoryList ')
    .then(res => {
        setBureauList(res.data);

       
    });

}

  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };

  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };

  const getLeadContactList = async (CId) => {

    var AUrl = global.APIURL + '/CorporateleadcontactEditClient/' + CId;

    axios.post(AUrl)
      .then(res => {

        setEmail(res.data[0].Email);
        setName(res.data[0].Name);
        setMobileNo(res.data[0].Mobilenumber);
        setDesignation(res.data[0].Designation);

      });
  }

  return (
    <React.Fragment>
      <Commheader />
      <div className="page-content">
        <MetaTags>
          <title>Corporate Service Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">

          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Corporate Service Detail</CardTitle>


              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14 btncolor "
                href="/dashboard"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2">
                        <CardTitle>Corporate Service Detail</CardTitle>
                        <hr />
                      </Col>
                   
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Type </p>
                        <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={NetworkType}
                          
                          />
                    
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Source </p>
                        <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={NetworkSource}
                          
                          />
                      
                      </Col>
                      <Col>
                        <div className="col-lg-3 col-md-6 col-12 mt-3">
                          <label htmlFor="EmailId" className="form-label">
                            EmailId
                          </label>
                          <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={EmailIdd}
                         
                          />

                        </div>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                        <p className="mt-3 mb-2 fontWeight500"> Address </p>
                        <div className="input-group auth-pass-inputgroup">
                          <textarea className="form-control" placeholder="Address" id="txtIndAddress" rows="3" name="txtIndAddress" defaultValue={Address} onChange={e => setAddress(e.target.value)}></textarea>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> State </p>
                        <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={StateName}
                           
                          />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> District </p>
                        <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={DistrictName}
                      
                          />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Tehsil </p>
                        <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={TehsilName}
                          
                          />
                      </Col>
                    
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <p className="mb-2 fontWeight500"> City/Village/Town </p>
                        <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={CityName}
                           
                          />
                      </Col>
                        
                   
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="text" className="" id="txtPinCode" defaultValue={Pincode} onChange={e => setPincode(e.target.value)} placeholder="Enter PinCode" />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Branch Code </p>
                        <Input type="text" className="" id="txtBranchCode" defaultValue={Branchcode} onChange={e => setBranchCode(e.target.value)} placeholder="Enter Branch Code" />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="">
                      <Col className="col-lg-12 mb-2">
                        <CardTitle>Contact Information</CardTitle>
                        <hr />
                      </Col>
                      <Col lg="12">
                      <table style={{ width: "100%" }}>
                          <tbody>
                            {LeadContactList.map((item, idx) => (
                              <tr id={"addr" + idx} key={idx}>
                                <td>
                                  <div data-repeater-list="group-a">
                                    <Row data-repeater-item className="align-items-end mb-3">
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Name
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlIdName} className="form-control" placeholder="Enter Name" defaultValue={item.Name} />
                                      </Col>
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Email
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlIdEmail} className="form-control" placeholder="Enter Email" defaultValue={item.Email} />
                                      </Col>
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Mobile No
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlId} className="form-control" maxLength={10} placeholder="Enter Mobile No" defaultValue={item.Mobilenumber} />
                                      </Col>
                                      <Col lg="2" className="">
                                        <Label htmlFor="name">
                                          Designation
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlIdDesignation} className="form-control" placeholder="Enter Designation" defaultValue={item.Designation} />
                                      </Col>


                                  


                                    </Row>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                  
                      </Col>

                    </Row>
                  </CardBody>
                </Card>

                <div className="row">
            <div className="col-md-12">
              <div className="card p-0">
                <div className="card-body">
                  <p className="maincommontitlefordahboard">Bureau Category</p>
                
                  <div className='maindasboardtableeditnew'>
                          <table className="table mt-0 mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col">Bureau</th>
                                <th scope="col">Action</th>
                               
                              </tr>
                            </thead>
                            <tbody>
                              {BureauList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td>{item.Title}</td>
                                 <td>   {/*<a className="btn btn-primary btn-sm font-14 btncolor" > Manage</a> */}
                                  <a href="/ManageInfo" className="btn btn-primary btn-sm font-14 btncolor" > Manage Info</a>
                                  </td>
                                
                                
                                
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                </div>

              </div>
            </div>

          </div>



        
              </Col>

            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageInformation;
