import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label } from "reactstrap";

const CorporateClientServiceActivities1 = () => {
  const [CorporateClientActivitiesStatus, setCorporateClientActivitiesStatus] = useState('Pending');
  const [BureauDetail, setBureauDetail] = useState([]);
  const [BureauDueDetail, setBureauDueDetail] = useState([]);
  const [BureauStepDetail, setBureauStepDetail] = useState([]);
  const [BureauStepActivitiesDetail, setBureauStepActivitiesDetail] = useState([]);
  const [BureauStepActivitiesActionGroupBy, setBureauStepActivitiesActionGroupBy] = useState([]);
  const [BureauStepActivitiesActionDetail, setBureauStepActivitiesActionDetail] = useState([]);
  const [BureauStepActivitiesStatus, setBureauStepActivitiesStatus] = useState([]);
  const history = useHistory();

  useEffect(() => {
    let CorporateLeadId = localStorage.getItem("CorporateLeadId") == null ? '0' : localStorage.getItem("CorporateLeadId") == '' ? '0' : localStorage.getItem("CorporateLeadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");

    if (CorporateLeadId != null && CorporateServiceId != null) {
      if (CorporateLeadId != "0") {

        // getCorporateLeadServiceStatus();
        getServiceBureauActivity();
      }
      else {
        history.push("/SerPackageDetail");
      }
    }
    else {
      history.push("/SerPackageDetail");
    }
  }, []);

  const getServiceBureauActivity = () => {

    let CorporateLeadId = localStorage.getItem("CorporateLeadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId");
    let VBureauId = localStorage.getItem("BureauId");

    const SData = {
      ServiceId: CorporateServiceId,
      CorporateLeadId: CorporateLeadId,
      BureauId: VBureauId
    }

    axios.post(global.APIURL + '/CorporateLeadServiceActivities ', SData)
      .then(res => {
        console.log("res--", res);
        setBureauStepDetail(res.data.Result[0].BureauStepDetail);
        setBureauStepActivitiesDetail(res.data.Result[0].BureauStepActivitiesDetail);
        setBureauStepActivitiesActionDetail(res.data.Result[0].BureauStepActivitiesActionDetail);
        setBureauStepActivitiesActionGroupBy(res.data.Result[0].BureauStepActivitiesActionGroupBy);

      });
  }

  const getBureauStepStatus=(VBureauId,VPaymentDueId,VServiceActivitiesId,VActivitiesStatusControlId)=>{

    let VCorporateLeadId = localStorage.getItem("CorporateLeadId");
    let VCorporateServiceId = localStorage.getItem("CorporateServiceId");

    const RequestArr = {
      ServiceId: VCorporateServiceId,
      CorporateLeadId: VCorporateLeadId,
      BureauId: VBureauId,
      PaymentDueId: VPaymentDueId,
      ServiceActivitiesId: VServiceActivitiesId
    };

    axios.post(global.APIURL + '/CorporateLeadServiceActivitiesLastStatus',RequestArr).then(res=>{
      console.log("resLastStatusCode",res);
      let LastStatusCode=res.data.Result[0].ServiceActivitiesLastStatus[0].ServiceActivitiesStatus;
      document.getElementById(VActivitiesStatusControlId).value=LastStatusCode;

    });



  }

  const saveActivity = (Vitem) => {

   

    let VPaymentDueId = localStorage.getItem("CorporateServiceDueId");
    let CorporateLeadId = localStorage.getItem("CorporateLeadId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId");
    let VLoginType = localStorage.getItem('LoginType');
    let VLoginUser = localStorage.getItem("LoginName");


    let VBureaStepActivityDetailArray = BureauStepActivitiesDetail.filter(x => x.MasterId == Vitem.Id);
    console.log("VBureaStepActivityDetailArray",VBureaStepActivityDetailArray);

    for (let i = 0; i < VBureaStepActivityDetailArray.length;i++) {
    
      let VBureaStepActivityDetail = VBureaStepActivityDetailArray[i];
      let VActionValue = document.getElementById(VBureaStepActivityDetail.FormControlId).value==null?"":document.getElementById(VBureaStepActivityDetail.FormControlId).value;

      let VBureauStepActivitiesActionGroupBy = BureauStepActivitiesActionGroupBy.filter(x => x.BureauId == Vitem.BureauId);

      VBureauStepActivitiesActionGroupBy = BureauStepActivitiesActionGroupBy.filter(x => x.PaymentDueId == VPaymentDueId);
      VBureauStepActivitiesActionGroupBy = BureauStepActivitiesActionGroupBy.filter(x => x.ServiceActivitiesId == Vitem.Id);
      VBureauStepActivitiesActionGroupBy = BureauStepActivitiesActionGroupBy.sort((a, b) => (a.RowIndexId - b.RowIndexId) ? -1 : 1);
      let LastIndexId = VBureauStepActivitiesActionGroupBy.length == 0 ? 0 : VBureauStepActivitiesActionGroupBy[0].RowIndexId + 1;


      const ActivityArr = {
        Id: 0,
        RowIndexId: LastIndexId,
        BureauId: Vitem.BureauId,
        CorporateLeadId: CorporateLeadId,
        ServiceId: CorporateServiceId,
        PaymentDueId: VPaymentDueId,
        ServiceActivitiesId: VBureaStepActivityDetail.ServiceActivitiesId,
        ServiceActivitiesActionId: VBureaStepActivityDetail.ServiceActivitiesActionId,
        ActionTitle: VBureaStepActivityDetail.ActionTitle,
        ActionValue: VActionValue,
        ActionType: VBureaStepActivityDetail.ActionType,
        ActionValueDate: VBureaStepActivityDetail.ActionValueDate,
        ServiceActivitiesActionStausId: VBureaStepActivityDetail.ServiceActivitiesActionStausId,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
        UserType: VLoginType,
        UserName: VLoginUser

      }

      axios.post(global.APIURL + '/CorporateLeadServiceActivitiesActionSave', ActivityArr).then(res => { });
      if(i==(VBureaStepActivityDetailArray.length-1)){
        alert("Activity Successfully saved");
        getBureauStepStatus(Vitem.BureauId,VPaymentDueId,Vitem.ServiceActivitiesId,Vitem.ActivitiesStatusControlId);
  
      }
    




    }


  }



  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client Service Activities | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">
          <Row className="mb-3">
            <Col className="col-lg-9 col-md-6 col-12">
              <div className="breadcrum ps-0">
                <CardTitle>Service Activities</CardTitle>
                <a href="/SerPackageDetail" className="me-2 accordfontcolor">Service Details</a>{" "}
                <span>/</span>
                <a href="/" className="me-2 ms-2 accordfontcolor">Service Activities</a>{" "}
              </div>
            </Col>
            <Col className="col-lg-3 col-md-6 col-12 mt-3 mt-md-0">
              <p className="mb-2 fontWeight500">Corporate Client Activities Status</p>
              <Input type="text" readOnly className="" id="txtCorporateClientActivitiesStatus" />
            </Col>
          </Row>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="ind-card" id="ind-card">
                <Row>
                  <Col className="col-lg-12">
                    <Card>
                      <CardBody>
                        <div className="accordion " id="accordionExample">
                          {
                            BureauStepDetail.map((item, idx) => (
                              <div className="accordion-item" key={idx}>
                                <h2 className="accordion-header accordbgcolor" id={item.AccordionId1}>
                                  <button className={item.RowId == 1 ? 'accordion-button' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target={item.collapse2} aria-expanded={item.RowId == 1 ? 'true' : 'false'} aria-controls={item.collapse1}>
                                    <Row className="duerowclass" >
                                      <Col className="col-lg-4">{item.Title}</Col>
                                      <Col className="col-lg-4 duedateclass"></Col>
                                      <Col className="col-lg-4 duestatusclass"> <input type="text" className="text-danger activity-status fw-bold textborderhide" aria-describedby="emailHelp" /></Col>
                                    </Row>
                                  </button>
                                </h2>
                                <div id={item.collapse1} className={item.RowId == 1 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} aria-labelledby={item.AccordionId1} data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <Row>

                                      {BureauStepActivitiesDetail.filter(x => x.MasterId == item.Id).map((item2, idx) => (<div className="col-md-6 col-lg-3 col-12" key={idx} >
                                        <form>
                                          <div className="mb-3">

                                            <label id={item2.FormControlId} className="form-label">{item2.ActionTitle}</label>

                                            {item2.ActionType == "Date" ?
                                              <input id={item2.FormControlId} placeholder={item2.FormControlPlaceholder} type="date" className="form-control" aria-describedby="emailHelp" /> : ''}
                                            {item2.ActionType == "Text" ?
                                              <input type="text" id={item2.FormControlId} placeholder={item2.FormControlPlaceholder} className="form-control" aria-describedby="emailHelp" /> : ''}
                                            {item2.ActionType == "TextArea" ?
                                              <textarea type="" id={item2.FormControlId} placeholder={item2.FormControlPlaceholder} rows="1" className="form-control" aria-describedby="emailHelp" /> : ''}
                                            {item2.ActionType == "Number" ?
                                              <input type="number" id={item2.FormControlId} placeholder={item2.FormControlPlaceholder} className="form-control" aria-describedby="emailHelp" /> : ''}


                                          </div>
                                        </form>
                                      </div>))}
                                    </Row>
                                    <Row>
                                      <div className="col-lg-4 co-md-6 col-12 mb-3">
                                        <button className="btn btn_success btn-sm font-14 me-2 btncolor" onClick={saveActivity(item)} >Save</button>
                                        <button className="btn btn_danger btn-sm  font-14" >Cancel</button>
                                        <input type="hidden" value="0"></input>
                                      </div>
                                    </Row>
                                    <Row>
                                      <Col className="col-lg-12">
                                        <hr />
                                        <CardTitle>Action Log</CardTitle>
                                        <hr />
                                      </Col>
                                      <div className="col-lg-12 mt-3">
                                        <div className="table-responsive">
                                          <table className="table table-bordered align-middle whitenowrapedit">
                                            <thead>
                                              <tr>
                                                <th className="textaligncenter">#</th>

                                                {BureauStepActivitiesDetail.filter(x => x.MasterId == item.Id).map((item3, idx) => <th scope="col" key={idx} >{item3.ActionTitle}</th>)}

                                                <th className="textaligncenter">Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>

                                              {BureauStepActivitiesActionGroupBy.filter(x => x.ServiceActivitiesId == item.Id).map((item4, idx) =>
                                                <tr key={idx}>
                                                  <td scope="col" className="text-center" key={idx}>{idx + 1}</td>

                                                  {BureauStepActivitiesActionDetail.filter(x => x.ServiceActivitiesId == item.Id && x.RowIndexId == item4.RowIndexId).sort((a, b) => (a.ServiceActivitiesActionId - b.ServiceActivitiesActionId)).map((item5, idx) =>

                                                    <td scope="col" key={idx}>

                                                      {item5.ActionType == "Date" ? item5.SActionValueDate : item5.ActionValue}

                                                    </td>
                                                  )}

                                                  <td className="textaligncenter">
                                                    <button className="text-primary deletebuttonclass" ><i className="mdi mdi-pencil font-size-18" ></i></button>
                                                    <button className="text-danger deletebuttonclass" ><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></button>
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            ))
                          }

                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}
export default CorporateClientServiceActivities1;
