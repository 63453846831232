import React, { Component, useEffect,useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";

const Navbar = () => {
  const [DashboardClass, setDashboardClass] = useState('nav-item dropdown')
  const [ManageofMeetingClass, setManageofMeetingClass] = useState('nav-item dropdown')
  const [BureauHelp, setBureauHelp] = useState('nav-item dropdown');
  const [Services, setServices] = useState('nav-item dropdown');

  useEffect(() => {
    let VDefaultPageId = localStorage.getItem("DefaultPageId");
    if (VDefaultPageId == 'Dashboard') {
      setDashboardClass('nav-item dropdown active');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
    }
    else if (VDefaultPageId == 'ManageofMeeting') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown active');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
    }
    else if (VDefaultPageId == 'BureauHelp') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown active');
      setServices('nav-item dropdown');
    }

    else {
      setDashboardClass('nav-item dropdown active');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
    }
  }, []);

  const PageLinkClick = async (PageLink) => {
    localStorage.setItem('DefaultPageId', PageLink);
    if (PageLink == 'Dashboard') {
      setDashboardClass('nav-item dropdown active');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown');
      window.location.href = "/dashboard";
    }
    else if (PageLink == 'ManageofMeeting') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown active');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
      window.location.href = "/CorContactInfo/CorLeadMomList";
    }
    else if (PageLink == 'BureauHelp') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown active');
      setServices('nav-item dropdown');
      window.location.href = "/CorContactInfo/BureauHelp";
    }
    else if (PageLink == 'Services') {
      setDashboardClass('nav-item dropdown');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown active');
      setServices('nav-item dropdown');
      window.location.href = "/Services";
    }
    else {
      setDashboardClass('nav-item dropdown active');
      setManageofMeetingClass('nav-item dropdown');
      setBureauHelp('nav-item dropdown');
      setServices('nav-item dropdown');
      window.location.href = "/dashboard";
    }
  }
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">


          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              className="navbar-collapse"
              id="topnav-menu-content"
            >

              <ul className="navbar-nav">

                <li className={DashboardClass} id="lidashboard">
                  <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('Dashboard')} >Dashboard</a>
                </li>
                <li className={ManageofMeetingClass} id="liCorLeadMomList">
                  <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('ManageofMeeting')}>Manage of Meeting</a>
                </li>
                <li className={BureauHelp} id="liBureauHelp">
                  <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('BureauHelp')}>Bureau Help</a>
                </li>
                <li className={Services} id="liServices">
                  <a className="nav-link dropdown-toggle arrow-none" onClick={e => PageLinkClick('Services')}>Services</a>
                </li>

              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Navbar;
